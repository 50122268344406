import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';


const backgroundImage =
  'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        // backgroundImage: `url("../images/landing-slim.png")`,
        backgroundColor: '#896caf', // Average color of the background image.
        // backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <StaticImage
        style={{ display: 'none' }}
        src='../images/landing-slim.png'
        alt="increase priority"
      />
     
    </ProductHeroLayout>
  );
}
