import * as React from "react";
import { Theme, styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { StaticImage } from "gatsby-plugin-image";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("xs")]: {
    height: "80vh",
    minHeight: 300,
    maxHeight: 420,
  },
  [theme.breakpoints.up("sm")]: {
    height: "80vh",
    minHeight: 300,
    maxHeight: 360,
  },
  [theme.breakpoints.up("xl")]: {
    height: "80vh",
    minHeight: 300,
    maxHeight: 570,
  },
}));

const BackgroundLg = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});

interface ProductHeroLayoutProps {
  sxBackground: SxProps<Theme>;
}

export default function ProductHeroLayout(
  props: React.HTMLAttributes<HTMLDivElement> & ProductHeroLayoutProps
) {
  const { sxBackground, children } = props;

  return (
    <ProductHeroLayoutRoot>
      <Container
        sx={{
          mt: 5,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        {/* <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "common.black",
            opacity: 0.5,
            zIndex: -1,
          }}
        /> */}
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: -2,
            display: { xs: "block", sm: "none" },
          }}
        >
          <StaticImage
            style={{
              // position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: -2,
            }}
            src="../images/landing.png"
            alt="increase priority"
            layout="fullWidth"
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: -2,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <StaticImage
            style={{
              // position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: -2,
            }}
            src="../images/landing-slim.png"
            alt="increase priority"
            layout="fullWidth"
          />
        </Box>
      </Container>
    </ProductHeroLayoutRoot>
  );
}
