import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "./Typography";
import { StaticImage } from "gatsby-plugin-image";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "#c3b5d7" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              marked="center"
              align="center"
              component="h2"
            >
              За детето
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <StaticImage src="../images/screenshots/wash.jpg" alt="wash" />
              <Typography variant="h6" sx={{ my: 5 }}>
                Измии зъбките
              </Typography>
              <Typography variant="h5">
                {"Мий своите зъбки "}
                {"всеки ден и получавай награди."}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <StaticImage
                src="../images/screenshots/wardrobe2.jpg"
                alt="wardrobe"
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Облечи своето зъбче
              </Typography>
              <Typography variant="h5">
                {
                  "Облечи своето зъбче в различни дрехи, добави му аксесоари и го направи модерен."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <StaticImage
                src="../images/screenshots/wardrobe1.jpg"
                alt="increase priority"
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Събери всички народни носии
              </Typography>
              <Typography variant="h5">
                {"Събери всички народни носии от Балканския полуостров."}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
