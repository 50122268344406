import * as React from "react";
import type { HeadFC } from "gatsby";
import AppAppBar from "../components/AppAppBar";
import AppFooter from "../components/AppFooter";
import ProductCategories from "../components/ProductCategories";
import ProductCTA from "../components/ProductCTA";
import ProductHero from "../components/ProductHero";
import ProductHowItWorks from "../components/ProductHowItWorks";
import ProductSmokingHero from "../components/ProductSmokingHero";
import ProductValues from "../components/ProductValuesChild";
import withRoot from "../components/withRoot";
import ProductValuesChild from "../components/ProductValuesChild";
import ProductValuesParent from "../components/ProductValuesParent";

const pageStyles = {
 
 
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const headingAccentStyles = {
  color: "#663399",
};
const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
};
const doclistStyles = {
  paddingLeft: 0,
};
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
};

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
};

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  display: `inline-block`,
  marginBottom: 24,
  marginRight: 12,
};

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
};

const docLinks = [
  {
    text: "TypeScript Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
    color: "#8954A8",
  },
  {
    text: "GraphQL Typegen Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/local-development/graphql-typegen/",
    color: "#8954A8",
  },
];

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative" as "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <React.Fragment>
        <AppAppBar />
        <ProductHero />      
        <ProductHowItWorks />
        <ProductCTA />
        <ProductValuesParent />
        <ProductValuesChild />   
        <AppFooter />
      </React.Fragment>
    </main>
  );
};

export default withRoot(IndexPage);

export const Head: HeadFC = () => <title>Начало</title>;
