import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import { useState } from "react";
import Snackbar from "./Snackbar";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "#c3b5d7", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          За приложението
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              <Box sx={item}>
                <Typography variant="h5" align="center">
                  Първото мобилно приложение на дентални клиники ЕО Дент. Идеята
                  на приложението съчетава в себе си както изграждането на
                  правилни навици у децата за орална хигиена, така и съдържа
                  игри, с които детето да се забавлява и мотивира. Приложението
                  съчетава образователни, мотивационни и занимателни елементи.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          style={{ backgroundColor: "#4d475e" }}
          size="large"
          variant="contained"
          component="a"
          href="https://apps.apple.com/us/app/%D0%B7%D1%8A%D0%B1%D1%87%D0%B5%D1%82%D0%BE-%D0%B5%D0%BE/id6444321631"
          sx={{ mt: 8 }}
        >
          Изтегли за iPhone
        </Button>

        <Button
          style={{ backgroundColor: "#4d475e" }}
          size="large"
          variant="contained"
          component="a"
          href="https://play.google.com/store/apps/details?id=com.toothy.eo"
          
          sx={{ mt: 8 }}
        >
          Изтегли за Android
        </Button>       
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
