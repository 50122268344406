import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";
import Snackbar from "../components/Snackbar";
import Button from "../components/Button";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

function ProductCTA() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState<string>("");
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitEmailCollector();    
  };

  const submitEmailCollector = () => {
    const url: string = `https://zubcheto-eo.com/api/emailCollect/add`;
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: "" },
      body: JSON.stringify({
        email: email,
      }),
    };
    fetch(url, requestOptions)
      .then((res) => {
        if (!res.ok) {
          return res.json();
        }
        
        return;
      })
      .then((res) => {
       
        if (res) {
          handleFailure(res);
        }else{
          setOpen(true);
          setEmail("");
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFailure = (err: any) => {
    const error = getStringFromValidationError(err);
    setErrorMessage(error);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setErrorMessage(null);
  };

  return (
    <Container component="section" sx={{ mt: 10, mb: 10, display: "flex" }}>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Грешка</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>Затвори</Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "#c3b5d7",
              py: 8,
              px: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ maxWidth: 400 }}
            >
              <Typography variant="h5">
                Уведоми ме по имейл когато приложението е налично.
              </Typography>
              <TextField
                noBorder
                placeholder="Вашият имейл"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                variant="standard"
                sx={{ width: "100%", mt: 3, mb: 2 }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: "100%" }}
              >
                Изпрати
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="Ще ви изпратим имейл веднага след като приложението е налично."
      />
    </Container>
  );
}

const getStringFromValidationError = (errorsModel: any): string => {
  const errorsArray: string[] = [];

  errorsModel.errors.forEach((error: any) => {
    error.errorMessages.forEach((errorMessage: any) => {
      errorsArray.push(errorMessage);
    });
  });

  const result = "\n" + errorsArray.filter(onlyUnique).join("\n");

  return result;
};

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

export default ProductCTA;
